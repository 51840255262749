<template>
  <ul
    class="social-share social-style--2 color-black d-flex justify-content-start liststyle"
  >
    <li v-for="(social, i) in socialList" :key="i">
      <a target="_blank" :href="social.url"
        ><i class="fab" :class="social.icon"></i
      ></a>
    </li>
  </ul>
</template>
<script>
  export default {
    data() {
      return {
        socialList: [
          {
            icon: "fa-github",
            url: "https://github.com/jbolo",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://pe.linkedin.com/in/jbolo",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/jbolo_r",
          },
          {
            icon: "fa-dev",
            url: "https://dev.to/jbolo",
          },
        ],
      };
    },
  };
</script>
